.ad {
  margin: 20px 10px;
}

.ad iframe {
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.665);
}

.loaderContainer {
  position: absolute;
  width: 320px;
  height: 480px;
  background-color: rgb(255, 255, 255);
  transition: 0.3s all ease;
}

.loaderContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50%;
  width: 40%;
}

.adTitle {
  color: #002940;
}

.adContent {
  max-width: 600px;
}

.specsButton {
  border-radius: 0;
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%, 0 100%);
  background-color: #002940;
}

.specsButton:hover {
  background-color: #002940dc;
}
