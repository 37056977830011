.logo {
  width: clamp(80px, 80%, 130px);
}

.format {
  color: #002940;
  transition: all ease-in-out 0.3s;
  border-bottom: solid 1px #ffffff;
}

.format:hover {
  color: #002940c1;
  border-bottom: solid 1px #002940c1;
}
