footer {
  color: #002940;
  background-color: #eae8e8;
}

.contactFooter {
  border-bottom: #002940 1px solid;
}

.contactFooter a {
  text-decoration: none;
  color: #002940;
  font: 600;
}

.logoFooter {
  width: clamp(60px, 10%, 100px);
}

.copyright {
  margin-bottom: 0;
}
