.outterLayer {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #000000b2;
  color: white;
  transition: all ease-in-out 0.3s;
}

.specsContainer {
  position: absolute;
  width: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 30%;
  z-index: 10;
  background-color: #002940;
  transition: all ease-in-out 0.3s;
  padding: 40px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
