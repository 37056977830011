@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;700&display=swap");

@import url("https://fonts.cdnfonts.com/css/helvetica-neue-5");

body {
  font-family: "Ubuntu", sans-serif;
}

h1 {
  font-family: "Helvetica Neue", sans-serif;
}

h2 {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 300;
}

h3 {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 200;
}

p {
  font-family: "Ubuntu", sans-serif;
}
