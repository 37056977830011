.titleContainer {
  background-color: #00283d;
  color: white;
  clip-path: polygon(0% 0, 100% 0, 98% 100%, 0 100%, 0 100%);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
}

.title {
  margin: 0;
}

.adsContainer {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  padding-left: 0;
}

.moreButtonCont {
  display: flex;
  justify-content: center;
  width: 100%;
}

.moreButton {
  border-radius: 0;
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%, 0 100%);
  background-color: #002940;
  white-space: nowrap;
}

.moreButton:hover {
  background-color: #002940dc;
}
